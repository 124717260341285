/* istanbul ignore file -- @preserve */
import { configure, defineRule } from 'vee-validate';
import { required, email, min, max, confirmed, numeric, url } from '@vee-validate/rules';
import { localize, type FieldValidationMetaInfo } from '@vee-validate/i18n';
import { requiredMessage, timezoneValidation, validateUnique } from './validationUtils';

export type CustomValidator = (
    value: unknown,
    context: FieldValidationMetaInfo,
) => boolean | string;

export function initializeVeeValidate() {
    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('confirmed', confirmed);
    defineRule('numeric', numeric);
    defineRule('url', url);
    // Define the custom timezone validation rule
    defineRule('timezone', timezoneValidation);
    defineRule('unique', validateUnique);
    defineRule(
        'custom',
        (value: unknown, [validator]: CustomValidator[], context: FieldValidationMetaInfo) =>
            !!validator && validator(value, context),
    );

    // TODO: configure Locale in the future.
    configure({
        generateMessage: localize({
            en: {
                messages: {
                    required: requiredMessage,
                    email: 'This field must be a valid email',
                    min: 'This field must be at least 0:{min} characters',
                    max: 'This field must be at most 0:{max} characters',
                    confirmed: 'The confirmation does not match',
                    numeric: 'This field must be a number',
                    unique: 'This value already exists',
                    url: 'This field must be a valid URL',
                },
                fields: {
                    password: {
                        required: 'Password is required',
                    },
                },
            },
        }),
    });
}
